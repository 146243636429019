<script setup lang="ts">
import type { CmsElementBioCircle } from "~/composables/useCustom";
import SharedHeadline from "~/components/shared/SharedHeadline.vue";
import SharedTopline from "~/components/shared/SharedTopline.vue";

const props = defineProps<{
  content: CmsElementBioCircle;
}>();

const { headlineText, toplineText, headlineType, toplineType, Boxes} = props.content?.data;

</script>
<template>
  <section class="relative">
    <div class="mb-10 lg:mb-[3.75rem]">
      <div class="container px-0 xl:px-5">
        <SharedTopline :headline="toplineText" :headlineType="toplineType"></SharedTopline>
        <SharedHeadline :headline="headlineText" :headlineType="headlineType"></SharedHeadline>
      </div>
    </div>
    <div class="prose-p:text-sm prose-p:text-skin-white">
      <div class="container max-w-[90rem] px-0 xl:px-10">
        <div class="flex flex-wrap justify-center gap-5">
          <div v-for="box in Boxes" 
               :key="box" 
               class="relative w-full xs:w-[calc(50%-10px)] md:w-[calc(33.333%-14px)] lg:w-[calc(25%-15px)]">
            <div class="bg-skin-theme p-6 text-skin-white text-center rounded-[0.625rem] min-h-[18.6rem] flex items-center">
              <div class="w-full">
                <div class="h-6 flex items-center justify-center mb-2 prose-img:h-6">
                  <NuxtImg v-if="box.boxImage && box.boxImage.media" loading="lazy" format="webp" :src="box.boxImage.media.url" />
                </div>
                <h4 class="font-bold font-base mb-5" v-html="box.boxTitle"></h4>
                <div class="prose-p:*" v-html="box.boxText"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img src="/images/layer2.svg" alt="" class="absolute right-4 -top-16 hidden lg:block">
  </section>
</template>
